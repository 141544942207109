import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import JSConfetti from 'js-confetti';

export default class extends Controller {
    static targets = ["team1", "team2", "slot", "button", "popup", "success", "error"];
    static values = {
        teams: Array,
        matchId: String,
        tournamentId: String,
        // [{"team1": "657bfeab-8ec2-49aa-b115-65e0bc033b59", "team2": "3ff22245-9df1-4932-8819-dfc76103b131", "matchId": "04f4e7a2-c0bd-4ac7-8e89-650143e13039"}, {"team1": "39d93e98-97bd-42c6-b86c-3eba3f834141", "team2": "09f215b5-bb9d-4285-8e1f-3a45f86539cf", "matchId": "546783f3-1ead-4966-848a-1d3c62a02980"}]
        definedMatches: Array,
        format: String,
        tournamentGroupTeamId: String
    };

    connect() {
        this.jsConfetti = new JSConfetti();
        this.prepareTeams();

        if (this.hasDefinedMatchesValue) {
            this.validateDefinedMatches()
        }
    }

    prepareTeams() {
        this.teamCards = this.teamsValue.map(teamId => {
            const teamElement = document.querySelector(`[data-team-id="${teamId}"]`);
            return teamElement ? { id: teamId, element: teamElement.cloneNode(true) } : null;
        }).filter(team => team !== null);

        this.shuffleTeamCards();
        this.assignRandomTeamsToSlots();
    }

    validateDefinedMatches() {
        // console.log('definedMatches:', this?.definedMatchesValue)

        this.definedMatchesValue = this?.definedMatchesValue?.filter(value => {
            return value.team1
                && value.team2
                && value.team1 != value.team2
                && this.teamsValue.includes(value.team1)
                && this.teamsValue.includes(value.team2)
        }) ?? [];

        // console.log('validated definedMatches: ', this?.definedMatchesValue, this.teamsValue)
    }

    shuffleTeamCards() {
        for (let i = this.teamCards.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [this.teamCards[i], this.teamCards[j]] = [this.teamCards[j], this.teamCards[i]];
        }
    }

    assignRandomTeamsToSlots() {
        this.slotTargets.forEach((slot, index) => {
            if (!slot.classList.contains('team1') && !slot.classList.contains('team2')) {
                slot.innerHTML = this.teamCards[index]?.element.outerHTML || '';
            }
        });
    }

    start() {
        this.disableButton();
        this.selectedTeamIds = [];
        this.rotateThroughAllSlots();
    }

    rotateThroughAllSlots() {
        let rotations = 30;

        const interval = setInterval(() => {
            this.slotTargets.forEach(slot => {
                const randomTeam = this.randomizeSelection();
                slot.innerHTML = randomTeam.element.outerHTML;
            });

            if (--rotations <= 0) {
                clearInterval(interval);
                this.selectFinalTeams();
            }
        }, 100);
    }

    randomizeSelection() {
        const exclusions = []
        exclusions.push(...this?.definedMatchesValue.flatMap(match => [match.team1, match.team2]))

        let randomIndex;
        
        do {
            randomIndex = Math.floor(Math.random() * this.teamCards.length);
        } while (exclusions.includes(randomIndex));

        return { ...this.teamCards[randomIndex] };
    }

    async selectFinalTeams() {
        console.log('selectFinalTeams: ', this.definedMatchesValue)
        if(('round_robin', 'group').includes(this.formatValue)) {
            await this.selectFinalTeamsForRoundRobin();
        } else {
            await this.selectFinalTeamsForKnockOut();
        }
    }

    async selectFinalTeamsForRoundRobin() {
        console.log('selectFinalTeamsForRoundRobin: ', this.definedMatchesValue)

        let randomTeam

        if (!randomTeam) {
            randomTeam = this.randomizeSelection();
        }

        this.team1Target.innerHTML = randomTeam.element.outerHTML;

        this.selectedTeamIds = [randomTeam.id];
        await this.updateMatchTeamsForTournamentGroupTeam();
    }

    async selectFinalTeamsForKnockOut() {
        console.log('selectFinalTeamsForKnockOut: ', this.definedMatchesValue)

        let team1RandomTeam
        let team2RandomTeam

        if (this?.definedMatchesValue?.map(match => match.matchId).includes(this?.matchIdValue)) {
            const selectedDefinedMatch = this?.definedMatchesValue?.find(match => match.matchId == this?.matchIdValue)

            team1RandomTeam = this.teamCards.find(card => card.id == selectedDefinedMatch.team1)
            team2RandomTeam = this.teamCards.find(card => card.id == selectedDefinedMatch.team2)
        }

        if (!team1RandomTeam || !team2RandomTeam) {
            team1RandomTeam = this.randomizeSelection();
            team2RandomTeam = this.randomizeSelection();
        }

        while (team1RandomTeam.id === team2RandomTeam.id) {
            team2RandomTeam = this.randomizeSelection();
        }

        this.team1Target.innerHTML = team1RandomTeam.element.outerHTML;
        this.team2Target.innerHTML = team2RandomTeam.element.outerHTML;

        this.selectedTeamIds = [team1RandomTeam.id, team2RandomTeam.id];
        await this.updateMatchTeamsForKnockOut();
    }

    async updateMatchTeamsForTournamentGroupTeam() {
        console.log('updateMatchTeams: ', this.selectedTeamIds, this)

        const body = {
            tournament_group_team_id: this.tournamentGroupTeamIdValue,
            team_id: this.selectedTeamIds[0]
        };

        try {
            const response = await post(`/tournaments/${this.tournamentIdValue}/organizer/draws/set_teams_to_tournament_group_team`, {
                body: JSON.stringify(body),
                responseKind: 'json'
            });

            if (!response.ok) throw new Error('Network response was not ok.');

            this.handleSuccess();
        } catch (error) {
            console.error('Error:', error);
            this.handleError();
        }
    }

    async updateMatchTeamsForKnockOut() {
        console.log('updateMatchTeams: ', this.selectedTeamIds)

        const body = {
            match_id: this.matchIdValue,
            team1_id: this.selectedTeamIds[0],
            team2_id: this.selectedTeamIds[1]
        };

        try {
            const response = await post(`/tournaments/${this.tournamentIdValue}/organizer/draws/${this.matchIdValue}/set_teams`, {
                body: JSON.stringify(body),
                responseKind: 'json'
            });

            if (!response.ok) throw new Error('Network response was not ok.');

            this.handleSuccess();
        } catch (error) {
            console.error('Error:', error);
            this.handleError();
        }
    }

    disableButton() {
        this.buttonTarget.disabled = true;
        this.buttonTarget.classList.add('opacity-50');
        this.buttonTarget.innerHTML = '<i class="fa-solid fa-tennis-ball fa-spin"></i>';
    }

    handleSuccess() {
        this.jsConfetti.addConfetti();
        if (this.successTarget) {
            this.successTarget.classList.remove('hidden');
        }
        this.resetButton('Next Match →', 'btn-blue');
        if (this.buttonTarget) {
            this.buttonTarget.dataset.action = "click->draw#nextMatch";
        }
    }

    handleError() {
        if (this.errorTarget) {
            this.errorTarget.classList.remove('hidden');
        }
        this.resetButton('Draw again 🤞', 'btn-primary');
    }

    resetButton(text, btnClass) {
        this.buttonTarget.disabled = false;
        this.buttonTarget.classList.remove('opacity-50', 'btn-tertiary', 'animate-pulse');
        this.buttonTarget.classList.add(btnClass);
        this.buttonTarget.innerHTML = text;
    }

    nextMatch() {
        window.location.reload();
    }
}